import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
  return (
    <>
      <div className="container mx-auto px-4">
        <Header />
        {children}
        <Footer />
      </div>
      <div
        className="g-recaptcha"
        data-sitekey="6LePp7MZAAAAABijZYD10oQqin6LC8ERlJ_CD0xF"
        data-size="invisible"
      >
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
